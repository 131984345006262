import differenceInDays from 'date-fns/differenceInDays';
import { useBusinessStore } from '@/stores/business';
export default defineComponent({
  name: 'WidgetTrialExpiration',
  props: {
    trialEndDate: {
      type: Date,
      required: true
    },
    fullWidth: Boolean
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    return {
      businessStore: businessStore
    };
  },
  computed: {
    expiresInHelper: function expiresInHelper() {
      if (this.daysTrialEnds > 1) return 'multipleDays';
      if (this.daysTrialEnds === 1) return 'oneDay';
      return 'lessOneDay';
    },
    urgency: function urgency() {
      if (this.daysTrialEnds <= 7) return 'urgent';
      return 'regular';
    },
    indicatorColor: function indicatorColor() {
      if (this.urgency === 'regular') return 'green';
      return 'red';
    },
    daysTrialEnds: function daysTrialEnds() {
      return differenceInDays(this.trialEndDate, new Date());
    },
    dynamicTranslations: function dynamicTranslations() {
      return {
        oneDay: this.$t('widget.trial.expiration.title_one_day', {
          number: this.daysTrialEnds,
          class: 'trial-expiration__title--red'
        }),
        lessOneDay: this.$t('widget.trial.expiration.title_less_one_day', {
          class: 'trial-expiration__title--red'
        }),
        multipleDays: this.$t('widget.trial.expiration.title_multiple_days', {
          number: this.daysTrialEnds,
          class: "trial-expiration__title--".concat(this.indicatorColor)
        })
      };
    }
  },
  methods: {
    trackAndNavigate: function trackAndNavigate() {
      if (this.$matomo) {
        this.$matomo.trackEvent('button', 'trial payment upgrade', 'click upgrade button on trial widget');
      }
      if (this.businessStore.isPrePricing2023) {
        void this.$store.dispatch('dialogs/showAddPaymentDetailsOnUpgrade', true);
      } else {
        void this.$router.push({
          name: 'business-upgrade'
        });
      }
    }
  }
});