import { render, staticRenderFns } from "./BannerPaymentFailed.vue?vue&type=template&id=73a9b881&scoped=true"
import script from "./BannerPaymentFailed.vue?vue&type=script&lang=ts&setup=true"
export * from "./BannerPaymentFailed.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./BannerPaymentFailed.vue?vue&type=style&index=0&id=73a9b881&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a9b881",
  null
  
)

export default component.exports