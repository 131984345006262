<template>
  <in-page-nav-bottom v-if="$vuetify.breakpoint.smAndDown" :navigation="navArray" />
</template>

<script lang="ts">
import Vue from 'vue'

import InPageNavBottom from '~/components/in-page-nav/InPageNavBottom.vue'

export default Vue.extend({
  name: 'BusinessMembersNav',
  components: {
    InPageNavBottom,
  },
  computed: {
    navArray() {
      const nav = [
        {
          routeName: 'business-members',
          displayTitle: this.$t('business.members.navigation.members'),
          icon: '$vuetify.icons.multiple',
        },
        {
          routeName: 'business-members-domains',
          displayTitle: this.$t('business.members.navigation.domains'),
          icon: '$vuetify.icons.at',
        },
      ]

      return nav
    },
  },
})
</script>

<style lang="sass"></style>
