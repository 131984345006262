import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import differenceInDays from 'date-fns/differenceInDays';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { Fragment } from 'vue-fragment';
import DialogRelationshipCsat from '@/components/dialogs/DialogRelationshipCsat.vue';
import GeneralCsat from '@/components/feedback/GeneralCsat.vue';
export default defineComponent({
  components: {
    DialogRelationshipCsat: DialogRelationshipCsat,
    GeneralCsat: GeneralCsat,
    Fragment: Fragment
  },
  setup: function setup() {
    var userStore = useUserStore();
    var businessStore = useBusinessStore();
    var userAttributes = computed(function () {
      return userStore.attributes;
    });
    var userIsAdmin = computed(function () {
      return userStore.isBusinessAdmin;
    });
    // Fake old world structure
    var billingStatus = computed(function () {
      return businessStore.billingStatus;
    });
    return {
      userAttributes: userAttributes,
      userIsAdmin: userIsAdmin,
      billingStatus: billingStatus
    };
  },
  data: function data() {
    return {
      showGeneralCsat: false,
      generalCsat: '',
      showTransactionalCsatForm: false,
      showRelationalCsatForm: false
    };
  },
  created: function created() {
    var _this$userAttributes$, _this$userAttributes$2;
    var rCsatLocalStorageValue = localStorage.getItem('r-csat');
    var days = differenceInDays(new Date(), utcToZonedTime((_this$userAttributes$ = (_this$userAttributes$2 = this.userAttributes.accepted_business_invite) === null || _this$userAttributes$2 === void 0 ? void 0 : _this$userAttributes$2[0]) !== null && _this$userAttributes$ !== void 0 ? _this$userAttributes$ : '', Intl.DateTimeFormat().resolvedOptions().timeZone, {
      timeZone: 'UTC'
    }));
    this.showGeneralCsat = days > 1 && !!this.userIsAdmin && this.billingStatus === 'trialing' && (
    /**
     * Case: No 'dismissed*' in localstorage:
     * we want to show the general csat banner
     */
    !rCsatLocalStorageValue ||
    /**
     * Case: If old 'dismissed' value set in localstorage,
     * we want to show the general csat banner again
     */
    rCsatLocalStorageValue === 'dismissed' || (
    /**
     * Case: If new 'dismissed-<timestamp>' value set in localstorage,
     * we want to show the general csat banner again
     */
    rCsatLocalStorageValue.includes('dismissed') && differenceInDays(new Date(), new Date(Number(rCsatLocalStorageValue.replace('dismissed-', '')))) > 10));
  }
});