<template>
  <v-dialog v-model="isShown" width="632">
    <v-card data-cy="addMemberDialog">
      <v-card-title>
        {{ $t('business.members.add_members_dialog_title') }}
      </v-card-title>
      <v-card-text>
        <email-chips-input
          ref="newMembersEmailInput"
          placeholder="name@company.com, name@company.com"
          @setEmailValidity="newMemberEmailsAreValid = $event"
          @setEmails="$emit('setEmails', $event)"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" x-large color="info" outlined @click="isShown = false">
          {{ $t('global.maybe_later') }}
        </v-btn>
        <v-spacer />
        <v-btn
          x-large
          color="info"
          :disabled="!newMemberEmailsAreValid"
          :loading="loading"
          data-cy="submitAddMembersButton"
          @click="$emit('addMembers')"
        >
          {{ $t('business.members.add_members_new') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

import EmailChipsInput from '~/components/EmailChipsInput.vue'

export default Vue.extend({
  components: {
    EmailChipsInput,
  },
  props: {
    value: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      newMemberEmailsAreValid: false,
    }
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean): void {
        this.$emit('input', newValue)
      },
    },
  },
  watch: {
    isShown(value: boolean) {
      if (!value) {
        // Reset Email Chips Input
        // eslint-disable-next-line no-extra-semi
        ;(this.$refs.newMembersEmailInput as Vue & { reset: () => void }).reset()
      }
    },
  },
})
</script>

<style></style>
