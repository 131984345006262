import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxEmails: {
      type: Number,
      default: null
    },
    allowEmptyInput: {
      type: Boolean,
      default: false
    },
    presetEmailList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup() {
    var _useValidation = useValidation(),
      isEmail = _useValidation.isEmail;
    return {
      isEmail: isEmail
    };
  },
  data: function data() {
    return {
      emailChips: this.presetEmailList || [],
      emailInput: '',
      fullEmailList: [],
      emailsAreValid: false
    };
  },
  watch: {
    // Addresses the case of a user who has entered email
    // but not yet caused the email to turn into a chip
    emailInput: function emailInput(newEmailInput) {
      var validEmailInInput = this.validateEmails([newEmailInput]) === true;
      var fullEmailList = validEmailInInput ? [].concat(_toConsumableArray(this.emailChips), [newEmailInput]) : this.emailChips;
      this.fullEmailList = fullEmailList;
      this.checkEmailListValidity();
      if (validEmailInInput) this.emitData();
    },
    emailChips: function emailChips(_emailChips) {
      this.fullEmailList = _emailChips;
      this.checkEmailListValidity();
      this.emitData();
    },
    fullEmailList: function fullEmailList() {
      this.checkEmailListValidity();
    }
  },
  mounted: function mounted() {
    var _this = this;
    // Code that will run only after the entire view has been rendered
    void this.$nextTick().then(function () {
      // This function is an ugly hack to replace the listener @paste="onPasteEmails" in the emails v-combobox
      // Since Vuetify 2.5.x it seems v-combobox no longer supports @paste
      var target = document.querySelector('.email-chips-input .v-input__control .v-input__slot .v-select__slot .v-select__selections input');
      if (target) {
        target.addEventListener('paste', function (event) {
          event.preventDefault();
          _this.onPasteEmails(event);
        });
      }
    });
  },
  methods: {
    removeEmail: function removeEmail(item) {
      this.emailChips.splice(this.emailChips.indexOf(item), 1);
      this.emailChips = _toConsumableArray(this.emailChips);
      this.fullEmailList = this.emailChips;
      if (this.emailsAreValid) this.emitData();
    },
    validateEmails: function validateEmails(emails) {
      return emails.every(this.isEmail) || this.$t('global.email_invalid');
    },
    onPasteEmails: function onPasteEmails(event) {
      var _ref, _paste$split$filter;
      // Get pasted contents
      var paste = (_ref = event.clipboardData || window.clipboardData) === null || _ref === void 0 ? void 0 : _ref.getData('text');
      // Split pasted string and remove empty elements
      var pastedEmails = (_paste$split$filter = paste === null || paste === void 0 ? void 0 : paste.split(/,| |;/).filter(Boolean)) !== null && _paste$split$filter !== void 0 ? _paste$split$filter : [];
      // Create chips for the addressess which did not already exist
      var _iterator = _createForOfIteratorHelper(pastedEmails),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var email = _step.value;
          if (!this.emailChips.includes(email)) this.$set(this.emailChips, this.emailChips.length, email);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    emitData: function emitData() {
      if (this.emailsAreValid) this.$emit('setEmails', this.fullEmailList);else this.$emit('setEmails', []);
    },
    reset: function reset() {
      this.emailChips = [];
      this.emailInput = '';
    },
    checkEmailListValidity: function checkEmailListValidity() {
      var _this$emailInput;
      var oneEmailExists = this.fullEmailList.length > 0;
      var allChipsAreValid = this.validateEmails(this.emailChips) === true;
      var invalidInputExists = ((_this$emailInput = this.emailInput) === null || _this$emailInput === void 0 ? void 0 : _this$emailInput.length) > 0 && this.validateEmails([this.emailInput]) !== true;
      var tooManyEMails = this.maxEmails ? this.fullEmailList.length > this.maxEmails : false;
      this.emailsAreValid = (oneEmailExists || this.allowEmptyInput) && allChipsAreValid && !invalidInputExists && !tooManyEMails;
      this.$emit('setEmailValidity', this.emailsAreValid);
    }
  }
});