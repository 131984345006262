var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loadingTable || _vm.tableData.length > 0)?_c('div',[_c('div',{staticClass:"businessmembers__title pb-6"},[_vm._v("\n    "+_vm._s(_vm.$tc('business.members.invited.table_title', _vm.tableData.length, _vm.tableData.length))+"\n  ")]),_vm._v(" "),_c('v-data-table',{staticClass:"business__invitee__table",attrs:{"footer-props":{
      showFirstLastPage: true,
      firstIcon: '$vuetify.icons.arrow_first_page',
      lastIcon: '$vuetify.icons.arrow_last_page',
      'disable-items-per-page': true,
      'items-per-page-options': [3],
      'show-first-last-page': false,
    },"header-props":{
      'sort-icon': '$vuetify.icons.arrow_down',
    },"headers":_vm.tableHeaders,"items":_vm.tableData,"items-per-page":3,"loading":_vm.loadingTable,"loading-text":_vm.$t('global.searching'),"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.loadingTable)?_c('p'):_vm._e()]},proxy:true},{key:`item.options`,fn:function({ item }){return [_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","ripple":false,"data-cy":"inviteeOptionsButton"}},on),[_c('v-icon',{attrs:{"color":"text"}},[_vm._v(" $vuetify.icons.more_vert ")])],1)]}}],null,true)},[_vm._v(" "),_c('v-list',{staticClass:"pa-1",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"danger-on-hover",on:{"click":function($event){return _vm.revokeInvitation(item.username)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("$vuetify.icons.ban")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('business.members.invited.revoke'))+" ")])],1)],1)],1)],1)]}}],null,true)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }