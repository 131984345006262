<template>
  <div v-if="loadingTable || tableData.length > 0">
    <div class="businessmembers__title pb-6">
      {{ $tc('business.members.invited.table_title', tableData.length, tableData.length) }}
    </div>
    <v-data-table
      class="business__invitee__table"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: '$vuetify.icons.arrow_first_page',
        lastIcon: '$vuetify.icons.arrow_last_page',
        'disable-items-per-page': true,
        'items-per-page-options': [3],
        'show-first-last-page': false,
      }"
      :header-props="{
        'sort-icon': '$vuetify.icons.arrow_down',
      }"
      :headers="tableHeaders"
      :items="tableData"
      :items-per-page="3"
      :loading="loadingTable"
      :loading-text="$t('global.searching')"
      mobile-breakpoint="0"
    >
      <template #no-data>
        <!-- Suppress default 'no data' text of v-table while loading with empty p -->
        <p v-if="loadingTable" />
      </template>
      <template #[`item.options`]="{ item }">
        <v-menu bottom right offset-y>
          <template #activator="{ on }">
            <v-btn text icon color="primary" :ripple="false" data-cy="inviteeOptionsButton" v-on="on">
              <v-icon color="text"> $vuetify.icons.more_vert </v-icon>
            </v-btn>
          </template>
          <v-list dense class="pa-1">
            <v-list-item class="danger-on-hover" @click="revokeInvitation(item.username)">
              <v-list-item-action>
                <v-icon>$vuetify.icons.ban</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ $t('business.members.invited.revoke') }} </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import type { TranslateResult } from 'vue-i18n'

interface TableHeader {
  text: TranslateResult
  value?: string
  sortable: boolean
  class?: string
  align?: string
  filterable?: boolean
}

interface InviteeItem {
  username: string
  email: string
}

export default Vue.extend({
  props: {
    reload: Number,
  },
  data() {
    return {
      loadingTable: true,
      tableData: [] as InviteeItem[],
    }
  },
  computed: {
    businessState(): BusinessState | undefined {
      return (this.$store.state as RootState).business
    },
    tableHeaders(): TableHeader[] {
      return [
        {
          text: this.$t('business.members.invited.table_column'),
          value: 'email',
          sortable: true,
        },
        {
          text: '',
          value: 'options',
          sortable: false,
          class: 'shrink',
          align: 'right',
          filterable: false,
        },
      ]
    },
  },
  watch: {
    reload() {
      void this.resetList()
    },
  },
  mounted() {
    void this.resetList()
  },
  methods: {
    async resetList() {
      this.loadingTable = true
      await this.updateMembersListFromAPI()
      this.loadingTable = false
    },
    async revokeInvitation(userId: string) {
      this.loadingTable = true
      try {
        await this.$store.dispatch('business/revokeInvite', userId)
        void this.resetList()
      } catch (errors) {
        this.$emit('errors', errors)
      } finally {
        this.loadingTable = false
      }
    },
    async updateMembersListFromAPI() {
      const id = this.businessState?.company.id
      if (!id) return
      try {
        const response = await this.$axios.get<{ invited_members: InviteeItem[] }>(`businesses/${id}/invitations`)
        this.tableData = response.data.invited_members
      } catch {
        this.tableData = []
      }
    },
  },
})
</script>

<style lang="sass">
.business__invitee__table
  .v-data-footer
    justify-content: end
    height: 3.625rem
</style>
