import "core-js/modules/es.function.name.js";
import Logo from '@/components/Logo.vue';
import BusinessHelpMenu from '@/components/nav-menu/BusinessHelpMenu.vue';
import BusinessProfileMenu from '@/components/nav-menu/BusinessProfileMenu.vue';
import useUserPlanInfo from '@/composables/useUserPlanInfo';
export default defineComponent({
  components: {
    Logo: Logo,
    BusinessHelpMenu: BusinessHelpMenu,
    BusinessProfileMenu: BusinessProfileMenu
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup() {
    var bizStore = useBusinessStore();
    void bizStore.getBusiness();
    void bizStore.getSeals();
    var userPlanInfo = useUserPlanInfo();
    var companyHasManualInvoicingEnabled = computed(function () {
      return bizStore.isManuallyInvoiced;
    });
    var hideBillingNav = computed(function () {
      return companyHasManualInvoicingEnabled || userPlanInfo.activePricePlanId.value === 'sme_v2';
    });
    var seals = computed(function () {
      return bizStore.seals;
    });
    var bizName = computed(function () {
      return bizStore.name;
    });
    return {
      companyHasManualInvoicingEnabled: companyHasManualInvoicingEnabled,
      bizName: bizName,
      seals: seals,
      hideBillingNav: hideBillingNav
    };
  },
  computed: {
    isMobile: function isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    menuState: {
      get: function get() {
        return this.value;
      },
      set: function set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  watch: {
    isMobile: function isMobile(_isMobile) {
      if (_isMobile) this.menuState = false;
    }
  }
});