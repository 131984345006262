<template>
  <v-dialog v-model="isShown" width="500">
    <v-card>
      <v-card-title>
        {{ $t('business.members.remove_member_header') }}
      </v-card-title>
      <v-card-text>
        <div v-if="membersCount === 1">
          {{ $t('business.members.remove_member_prompt_one') }}
        </div>
        <div v-else>
          {{ $tc('business.members.remove_member_prompt', membersCount) }}
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn v-if="!loading" x-large color="error" outlined @click="isShown = false">
          {{ $tc('business.members.keep_members', membersCount) }}
        </v-btn>
        <v-spacer />
        <v-btn
          x-large
          color="error"
          data-cy="removeMemberConfirm"
          :disabled="loading"
          :loading="loading"
          @click="$emit('removeMember')"
        >
          {{ $t('business.members.remove_member') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  props: {
    value: Boolean,
    loading: Boolean,
    membersCount: Number,
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.value
      },
      set(newValue: boolean): void {
        this.$emit('input', newValue)
      },
    },
  },
})
</script>

<style></style>
