<template>
  <div class="csat d-flex flex-column flex-md-row align-center justify-space-between pl-8 pr-16 py-6">
    <div class="csat__text">{{ $t('feedback.general.csat.message') }}</div>
    <div class="emoticons">
      <v-icon size="36" class="emoticon emoticon--negative" @click="setSatisfactionLevel('negative')">
        $vuetify.icons.sad_emoticon
      </v-icon>
      <v-icon size="36" class="emoticon emoticon--neutral" @click="setSatisfactionLevel('neutral')">
        $vuetify.icons.neutral_emoticon
      </v-icon>
      <v-icon size="36" class="emoticon emoticon--positive" @click="setSatisfactionLevel('positive')">
        $vuetify.icons.happy_emoticon
      </v-icon>
    </div>
    <v-icon size="20" class="close" @click="close()"> $vuetify.icons.c_close </v-icon>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'GeneralCsat',
  methods: {
    close() {
      if (this.$matomo) {
        this.$matomo.trackEvent('business trial', 'r-csat', 'dismissed')
      }
      const nowTimeStamp = new Date().getTime()
      localStorage.setItem('r-csat', `dismissed-${nowTimeStamp}`)
      this.$emit('close')
    },
    setSatisfactionLevel(level) {
      localStorage.setItem('r-csat', 'set')
      this.$emit('select', level)
    },
  },
})
</script>

<style lang="sass" scoped>
.csat
  position: relative
  background-color: $c-widget
  border-radius: $bw
  width: max-content
  gap: 1.5rem
  max-width: 100%
  margin: 0 auto
  +maw
    width: 100%
    gap: 0.75rem
  &__text
    font-weight: 800
    font-size: 1.2em
    color: #293D66

.emoticons
  user-select: none
  > * + *
    margin-left: 0.5rem

.emoticon
  &--negative:hover
    color: $c-danger
  &--neutral:hover
    color: $c-yellow
  &--positive:hover
    color: $c-success

.close
  position: absolute
  top: 4 * $bw
  right: 4 * $bw
  +miw(md)
    top: 50%
    transform: translateY(-50%)
</style>
