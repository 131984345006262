<template>
  <fragment>
    <v-list-item v-for="(item, i) in navigation" :key="`${item}-${i}`" :to="{ name: item.routeName }" exact>
      <v-list-item-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.displayTitle }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </fragment>
</template>

<script lang="ts">
import Vue from 'vue'
import { Fragment } from 'vue-fragment'
export default Vue.extend({
  name: 'BusinessProfileMenu',
  components: {
    Fragment,
  },
  data() {
    return {
      navigation: [
        {
          routeName: 'business-profile',
          displayTitle: this.$t('business.navigation.business_profile'),
          icon: '$vuetify.icons.business_profile',
          cypressIdentifier: 'profileButton',
        },
        {
          routeName: 'business-profile-aes-for-business',
          displayTitle: this.$t('business.profile.navigation.aes'),
          icon: '$vuetify.icons.business_aes',
          cypressIdentifier: 'AESForBusinessButton',
        },
        {
          routeName: 'logout',
          displayTitle: this.$t('global.navigation.logout'),
          icon: '$vuetify.icons.leave',
          cypressIdentifier: 'logoutButton',
        },
      ],
    }
  },
})
</script>

<style></style>
