import "core-js/modules/es.number.constructor.js";
import EmailChipsInput from '~/components/EmailChipsInput.vue';
export default defineComponent({
  components: {
    EmailChipsInput: EmailChipsInput
  },
  props: {
    value: Boolean,
    loading: Boolean,
    seatsAvailable: {
      // `null` means we are in a trial and do not need to care about seats
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      count: 0,
      newMemberEmailsAreValid: false,
      emailInputInstance: null
    };
  },
  computed: {
    isShown: {
      get: function get() {
        return this.value;
      },
      set: function set(newValue) {
        this.$emit('input', newValue);
      }
    },
    canAddMembers: function canAddMembers() {
      if (this.newMemberEmailsAreValid) {
        return this.seatsAvailable === null || this.count <= this.seatsAvailable;
      }
      return false;
    }
  },
  watch: {
    isShown: function isShown(value) {
      if (!value) {
        var _this$emailInputInsta;
        // Reset Email Chips Input
        (_this$emailInputInsta = this.emailInputInstance) === null || _this$emailInputInsta === void 0 ? void 0 : _this$emailInputInsta.reset();
      }
    }
  },
  methods: {
    setEmails: function setEmails(emails) {
      this.count = emails.length;
      this.$emit('setEmails', emails);
    }
  }
});